import * as React from "react";
import slugify from "slugify";

const slugOptions = {
  lower: true,
  strict: true,
  trim: true,
};

const RichText = ({ heading, children, product }) => {
  const slug = heading ? slugify(heading, slugOptions) : null;

  if (children) {
    return (
      <section
        className={`rich-text${product ? " rich-text-product" : ""}`}
        aria-labelledby={heading ? `${slug}-heading` : null}
      >
        <div className="container">
          <div>
            {heading && (
              <h2 id={`${slug}-heading`} className="heading">
                {heading}
              </h2>
            )}
            {children}
          </div>
        </div>
      </section>
    );
  }

  return null;
};

export default RichText;
