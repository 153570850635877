import * as React from "react";
import { graphql } from "gatsby";
import { useIntl } from "react-intl";
import Layout from "../components/layout";
import Seo from "../components/seo";
import Hero from "../components/hero";
import ContactForm from "../components/contact-form";

const ContactPage = () => {
  const { formatMessage } = useIntl();
  const title = formatMessage({ id: "contactUs" });

  return (
    <Layout>
      <Seo title={title} />
      <Hero title={title} />
      <ContactForm />
    </Layout>
  );
};

export default ContactPage;

export const query = graphql`
  query ($locale: String!) {
    translations: contentfulTranslations(node_locale: { eq: $locale }) {
      translations {
        key
        value
      }
    }
  }
`;
