import * as React from "react";
import { renderRichText } from "gatsby-source-contentful/rich-text";
import { BLOCKS, INLINES } from "@contentful/rich-text-types";
import { useIntl } from "react-intl";
import Link from "./link";
import RichText from "./rich-text";

const FORM_IDS = {
  fr: "c9b72790-124f-4c91-aa6b-0405ab86e141",
  en: "1cfcd235-5cc9-4ff9-b165-0a6d89cea2ad",
};

const renderOptions = {
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => {
      const [{ value }] = node.content;

      if (value !== "" || node.content.length > 1) {
        return <p>{children}</p>;
      }

      return null;
    },

    [INLINES.HYPERLINK]: (node, children) => (
      <Link href={node.data.uri}>{children}</Link>
    ),
  },

  renderText: (text) => {
    return text
      .split("\n")
      .flatMap((text, index) => [index > 0 && <br key={index} />, text]);
  },
};

const ContactForm = ({ intro }) => {
  const { locale } = useIntl();

  React.useEffect(() => {
    window.hbspt?.forms.create({
      region: "eu1",
      portalId: "26849553",
      formId: `${FORM_IDS[locale]}`,
      target: "#hubspot-container",
    });
  }, [locale]);

  return (
    <RichText>
      <>
        {intro && renderRichText(intro, renderOptions)}
        <div id="hubspot-container" />
      </>
    </RichText>
  );
};

export default ContactForm;
